import { type CartItem, useCheckoutStore } from 'store/checkout'
import { usePatientStore } from 'store/patient'
import type { iProduct } from 'types/product'
import { isStorageAvailable } from './storage'
// TODO ADD EVENTS patient_registered forgot_password_started forgot_password_completed
// PAGE TITLES CLEAR ONE WITHOUT VENDOR THERE
// ADD SHIPPING TO TOTALS ????
// ADDING STEPS patient registration,
import * as Sentry from '@sentry/nextjs'
import { discoverLog } from '@services/client/discovery/log'

const formatItem = (item: CartItem) => ({
  item_name: `${item.title} [${item.variation?.map(e => e.label).join(', ')}]`.toLowerCase(),
  price: item.price / 100,
  quantity: 1,
  item_variant: item.variation?.find(e => e.attribute === 'pa_ep_frequency')?.label || 'One Time Purchase'
})

function gtag() {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push(arguments)
}

const logAll = (event: string, data: Record<string, any> = {}) => {
  data.timestamp = new Date().toISOString()
  // @ts-ignore
  gtag('event', event, data)

  if (!data.timestamp) {
    Sentry.captureMessage(`Missing timestamp for ${event}`, {
      level: 'warning',
      contexts: {
        event: {
          event,
          data
        }
      }
    })
  }

  window.parent.postMessage(
    {
      type: 'EVARO_ANALYTIC_EVENT',
      data: {
        event: `evaro_${event}`,
        data
      }
    },
    '*'
  )
}

export const logViewItem = (item: iProduct) => {
  logAll('view_item', {
    currency: 'GBP',
    item_name: item.title
  })
}

export const logAddToCart = (item: CartItem) => {
  logAll('add_to_cart', {
    currency: 'GBP',
    value: item.price / 100,
    items: [formatItem(item)]
  })
}

export const logViewCart = () => {
  logAll('view_cart', {
    currency: 'GBP',
    value: useCheckoutStore.getState().getCartTotal() / 100,
    items: useCheckoutStore.getState().cart.map(formatItem)
  })
}

export const logRemoveFromCart = (item: CartItem) => {
  logAll('remove_from_cart', {
    currency: 'GBP',
    value: item.price / 100,
    items: [formatItem(item)]
  })
}

export const logBeginCheckout = () => {
  logAll('begin_checkout', {
    currency: 'GBP',
    value: useCheckoutStore.getState().getCartTotal() / 100,
    items: useCheckoutStore.getState().cart.map(formatItem),
    coupon: useCheckoutStore.getState().coupon?.code
  })
}

export const logPurchase = (order: any) => {
  logAll('purchase', {
    transaction_id: order.meta.order_number,
    value: Number.parseFloat(order.payment.total) / 100,
    discount: order.payment.discount,
    sub_total: Number.parseFloat(order.payment.sub_total) / 100,
    shipping: Number.parseFloat(order.payment.shipping) / 100,
    shipping_method: order.shipping.method_title,
    currency: 'GBP',
    coupon: order.payment.coupons?.[0],
    items: order.items.map((el: any) => ({
      item_name: `${el.name} [${Object.values(el.variations).join(', ')}]`.toLowerCase(),
      price: Number.parseFloat(el.total) / 100,
      quantity: 1,
      item_variant: el.variations?.frequency || 'One Time Purchase'
    }))
  })
}

export const logLogout = () => {
  logAll("logout", {
    login_status: "logged_out",
  });
}

export const logLogin = () => {
  logAll("login", {
    method: "email",
    login_status: "logged_in",
  });
}

export const logLoginError = (method: string, error: string) => {
  logAll('login_failed', {
    method,
    error_info: error
  })
}

export const logSignUp = () => {
  logAll('sign_up', {
    method: 'email'
  })
}

export const setUserId = (userId: string) => {
  // gtag("set", { user_id: userId });
}

export const logDarkMode = () => {
  const darkMode = window.matchMedia('(prefers-color-scheme: dark)').matches
  // @ts-ignore
  gtag('set', 'user_properties', {
    dark_mode: darkMode ? 'true' : 'false',
    incognito_mode: isStorageAvailable() ? 'false' : 'true'
  })
}

export const logScreenREsolution = () => {
  // @ts-ignore
  gtag('set', 'screen_resolution', `${window.screen.width}x${window.screen.height}`)
}

export const logConsultationStarted = () => {
  logAll('consultation_started', {
    questionnaire_hash: usePatientStore.getState().questionnaire_id
  })
}

export const logConsultationCompleted = () => {
  logAll('consultation_completed', {
    questionnaire_hash: usePatientStore.getState().questionnaire_id
  })
}

export const logQuestionViewed = (question: string, consultation: string, skipped: boolean = false) => {
  logAll('question_viewed', {
    question_hash: question,
    consultation_hash: consultation,
    is_skipped: skipped
  })
}

export const logQuestionAnswered = (question: string, consultation: string, skipped: boolean = false) => {
  logAll('question_answered', {
    question_hash: question,
    consultation_hash: consultation,
    is_skipped: skipped
  })
}

let prevPage = globalThis.document?.referrer ?? ''

export const logPageView = () => {
  window.parent.postMessage(
    {
      type: 'EVARO_ANALYTIC_EVENT',
      data: {
        event: 'evaro_page_view',
        data: {
          page_title: document.title,
          page_location: window.location.href,
          page_path: window.location.pathname,
          page_referrer: prevPage,
          timestamp: new Date().toISOString()
        }
      }
    },
    '*'
  )

  prevPage = window.location.href
}

export const logAddShippingInfo = () => {
  logAll('add_shipping_info', {
    shipping_type: useCheckoutStore.getState().shippingType?.title
  })
}

export const logAddPaymentInfo = (type: string) => {
  logAll('add_payment_info', {
    payment_type: type
  })
}

export const logViewMenu = () => {
  logAll('view_menu')
}

export const logViewLogin = () => {
  logAll('view_login')
}

export const logViewSignUp = () => {
  logAll('view_signup')
}

export const logViewForgotPassword = () => {
  logAll('view_forgot_password')
}

export const logViewNewPassword = () => {
  logAll('view_new_password')
}

export const logViewOTP = () => {
  logAll('view_otp')
}

export const logProductChoice = (data: any) => {
  logAll('product_choice', data)
}

export const logDiscoveryOpened = () => {
  const hasSearch = new URLSearchParams(window.location.search).has('search')

  logAll('discovery_search_initiated', {
    source: hasSearch ? 'e-surgery' : 'connect'
  })
  discoverLog('Discovery Opened')
}

export const logDiscoveryClosed = () => {
  const hasSearch = new URLSearchParams(window.location.search).has('search')

  logAll('discovery_search_closed', {
    source: hasSearch ? 'e-surgery' : 'connect'
  })
  discoverLog('Discovery Closed')
}

export const logDiscoveryInputSubmitted = () => {
  const hasSearch = new URLSearchParams(window.location.search).has('search')

  logAll('discovery_query_submitted', {
    source: hasSearch ? 'e-surgery' : 'connect'
  })
}

export const logDiscoveryResetButton = () => {
  const hasSearch = new URLSearchParams(window.location.search).has('search')

  logAll('discovery_search_history_cleared', {
    source: hasSearch ? 'e-surgery' : 'connect'
  })
  discoverLog('Reset button clicked')
}

export const logDiscoveryLinkClick = (text: string, data?: any) => {
  const hasSearch = new URLSearchParams(window.location.search).has('search')

  logAll('discovery_search_result_clicked', {
    source: hasSearch ? 'e-surgery' : 'connect'
  })
  discoverLog(text, data && JSON.stringify(data, null, 2))
}

export const logDiscoveryDialogRequest = (input: string) => {
  discoverLog(`Dialog: request - "${input}"`)
}

export const logDiscoveryDialogResponse = (data: any) => {
  discoverLog(`Dialog: response - "${data.action}"`, JSON.stringify(data, null, 2))
}

export const logConsultationView = (number: number, all: number) => {
  logAll('consultation_viewed', {
    question_number: `Q-${number}`,
    question_number_of_total: `Q-${number} of ${all}`
  })
}

export const logRecoveredCartSuccess = () => {
  logAll('recovered_cart_success')
}

export const logRecoveredCartFailure = () => {
  logAll('recovered_cart_error')
}

export const logAppClosed = () => {
  logAll('app_closed')
}
